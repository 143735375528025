import { IconButton, Skeleton } from '@mui/material';
import {
  AssignedProductSave,
  PayerType,
  useGetStudentProductsQuery,
  useUpdateStudentProductsMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { CrossIcon, Loading, ModalHeader, ModalSmall } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import AccessDenied from '../../../../../components/common/AccessDenied';
import { useProfile } from '../../../../../context/profile/useProfile';
import { useRouter } from '../../../../../context/router/useRouter';
import useSchoolYears from '../../../../../hooks/useSchoolYears';
import {
  StudentProductsModalContent,
  StudentProductsModalState,
  UpdateStudentProductsForm,
} from './StudentProductsModalContent';

export const STUDENT_PRODUCTS_COMPANY_ID_PARAM = 'company_id';

export const StudentProductsModal: FC = () => {
  const [params] = useSearchParams();
  const { $t } = useIntl();
  const { schoolId = '' } = useAuth();
  const { schoolMembership, isFetching, user } = useProfile();
  const { permissions } = useAuth();
  const canEdit = permissions.includes('payer_and_product_assignment_manager');
  const { goBack } = useRouter();
  const { showError, showNotification } = useNotifications();
  const updateStudentProducts = useUpdateStudentProductsMutation();
  const { isLoading: isSaving } = updateStudentProducts;
  const invalidateCompanyQueries = useInvalidateListQueriesFor('company');
  const invalidateProductQueries = useInvalidateListQueriesFor('product');
  const { getConfirmation } = useConfirmationDialog();

  const { defaultValidity, isLoading: isYearsFetching } = useSchoolYears();

  const relationId = schoolMembership?.relation_id || '';

  const { data, isLoading } = useGetStudentProductsQuery(
    {
      relationId,
    },
    { enabled: !!schoolMembership, refetchOnMount: 'always' },
  );

  const paramsCompanyId = params.get(STUDENT_PRODUCTS_COMPANY_ID_PARAM);
  const currentCompanyId = data?.company_payer?.id;
  const companyId = paramsCompanyId ?? currentCompanyId;

  const handleSubmit = useCallback(
    async (data: UpdateStudentProductsForm) => {
      const products: AssignedProductSave[] = data.products.map(
        ({ id, variant, payer_type, discount_percent }) => ({
          id,
          variant: {
            type_name: variant.type_name,
            id: variant.id,
            frequency_id: variant.frequency_id,
          },
          payer_type,
          discount_percent: discount_percent || 0,
        }),
      );

      const companyPayerSelected = data.products.some((p) => p.payer_type === PayerType.Company);

      if (data.companyPayerId && !companyPayerSelected) {
        const isConfirmed = await getConfirmation({
          message: $t(
            {
              id: currentCompanyId
                ? 'profile-HaveDeselectedCompanyAsPayerConfirmation'
                : 'profile-HaveNotSelectedCompanyAsPayerConfirmation',
            },
            { studentName: user && getUserFullName(user) },
          ),
        });
        if (!isConfirmed) return;
      }

      updateStudentProducts.mutateAsync(
        {
          ...data,
          companyPayerId: companyPayerSelected ? data.companyPayerId : undefined,
          studentId: relationId,
          products,
        },
        {
          onError: showError,
          onSuccess: () => {
            showNotification({
              textId: paramsCompanyId
                ? 'companies-AssignmentsHaveBeenSuccessfullyUpdated'
                : 'products-ProductsAndPayersSaved',
              type: 'success',
            });
            invalidateProductQueries();
            invalidateCompanyQueries();
            goBack();
          },
        },
      );
    },
    [
      $t,
      currentCompanyId,
      paramsCompanyId,
      relationId,
      updateStudentProducts,
      user,
      getConfirmation,
      goBack,
      invalidateCompanyQueries,
      invalidateProductQueries,
      showError,
      showNotification,
    ],
  );

  if (!canEdit) {
    return (
      <ModalSmall open onClose={goBack}>
        <AccessDenied />
      </ModalSmall>
    );
  }

  return (
    <ModalSmall open onClose={goBack}>
      <ModalHeader title={user ? getUserFullName(user) : <Skeleton width="50%" />} active>
        <IconButton onClick={goBack}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      {isFetching || !data || isLoading || isYearsFetching || !defaultValidity?.id ? (
        <Loading />
      ) : (
        <StudentProductsModalContent
          schoolId={schoolId}
          initialState={
            params.has(StudentProductsModalState.AddProducts)
              ? StudentProductsModalState.AddProducts
              : StudentProductsModalState.SelectPayers
          }
          relationId={relationId}
          onSubmit={handleSubmit}
          adult={data.default_payer}
          company={data.company_payer}
          initCompanyId={companyId}
          products={data.products}
          isSaving={isSaving}
          yearId={defaultValidity.id}
        />
      )}
    </ModalSmall>
  );
};
