import { Button, IconButton, Stack, Switch, Tooltip, Typography } from '@mui/material';
import {
  Product,
  ProductForm,
  ProductFormType,
  ProductSaveVariant,
  ProductTrigger,
  SchoolYear,
  useCheckProductNameUniqueMutation,
  useGetSchoolPaymentFrequencies,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { PropertyTypeSelect, SelectContentSkeleton } from '@schooly/components/filters';
import { FormTextField } from '@schooly/components/form-text-field';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  InformationIcon,
  Loading,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderInput,
  ModalLarge,
  ModalSmall,
  Spin,
} from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { FC, useCallback, useMemo, useState } from 'react';
import { Controller, ControllerRenderProps, FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { getSortedFrequencies, useSchoolYearsInProduct } from '../helpers';
import { SchoolProductModalHeader } from '../SchoolProductModalHeader';
import { ProductSubscriptionSection } from './ProductSubscriptionSection/ProductSubscriptionSection';
import { SchoolProductCreateModalVariants } from './SchoolProductCreateModalVariants';

type SchoolProductCreateModalContentProps = {
  initialState?: ViewState;
  product?: Product;
  schoolId: string;
  onSave: (v: ProductForm) => void;
  onDelete?: () => void;
  onClose: () => void;
  isSaving: boolean;
  isDeleting: boolean;
};

type IntersectionId =
  | {
      ageGroupId: IntersectsAll;
      subjectId: string;
    }
  | { ageGroupId: string; subjectId: IntersectsAll }
  | {
      ageGroupId: string;
      subjectId: string;
    };

export type IntersectionIds = {
  halfDayIds: IntersectionId[];
  fullDayIds: IntersectionId[];
};

export enum ViewState {
  General = 'general',
  Variants = 'variants',
}

const defaultValues: ProductForm = {
  name: '',
  description: '',
  obligatory: false,
  single_type: true,
  unique_types: true,
  assignment: { one_type: false },
  trigger: {
    trigger_type: ProductTrigger.RegistrationUpdate,
    extra_data: { status: '' },
  },
  types: [
    {
      name: 'Standard',
      billing_connection: {
        account_id: '',
        legal_entity_id: '',
      },
      variants: [
        {
          half_day: false,
          prices: [],
          subjects: [],
          age_groups: [],
        },
      ],
    },
  ],
};

export const SchoolProductCreateModalContent: FC<SchoolProductCreateModalContentProps> = ({
  initialState = ViewState.General,
  product,
  schoolId,
  onClose,
  onSave,
  onDelete,
  isDeleting,
  isSaving,
}) => {
  const { $t } = useIntl();
  const [state, setState] = useState<ViewState>(initialState);
  const checkProductNameUnique = useCheckProductNameUniqueMutation();
  const { showError } = useNotifications();
  const { propertiesMap } = useSchoolProperties({ schoolId, userType: SchoolUserRole.Student });
  const { getConfirmation } = useConfirmationDialog();

  const { permissions } = useAuth();

  const canShowYearInSelect = useCallback(
    (year?: SchoolYear) => Boolean(year && !isDateInPast(year.end)),
    [],
  );
  const { selectedYear, setSelectedYear, defaultYear, yearsForSelect } =
    useSchoolYearsInProduct(canShowYearInSelect);

  const { data } = useGetSchoolPaymentFrequencies(
    { school_id: schoolId, year_id: selectedYear?.id ?? '' },
    { enabled: !!selectedYear?.id },
  );

  const { sortedFrequencies, frequencies } = useMemo(() => {
    const frequencies = data?.frequencies ? data.frequencies : [];

    return {
      frequencies,
      sortedFrequencies: getSortedFrequencies(frequencies).filter((f) => !!f.in_use),
    };
  }, [data?.frequencies]);

  const defaultTypes = useMemo(() => {
    if (product?.types.length) {
      return product.types.map((t) => ({
        ...t,
        billing_connection: {
          account_id: t.billing_connection.legal_entity_account.id,
          legal_entity_id: t.billing_connection.legal_entity_id,
          legal_entity_currency: t.billing_connection.legal_entity_currency,
        },
        year_id: selectedYear?.id,
      }));
    }

    return defaultValues.types.map((t) => ({
      ...t,
      year_id: selectedYear?.id,
    }));
  }, [product?.types, selectedYear?.id]);

  const form = useForm<ProductForm>({
    defaultValues: product
      ? {
          name: product.name,
          description: product.description,
          obligatory: product.obligatory,
          trigger: product.trigger,
          single_type: product.single_type,
          unique_types: product.unique_types,
          assignment: { one_type: product.assignment.one_type },
          types: defaultTypes,
        }
      : { ...defaultValues, types: defaultTypes },
  });

  const name = form.watch('name');
  const obligatory = form.watch('obligatory');
  const trigger = form.watch('trigger');
  const isSingleType = form.watch('single_type');
  const isUniqueTypes = form.watch('unique_types');
  const oneTypeAssignment = form.watch('assignment.one_type');
  const types = form.watch('types');

  const canViewFrequency = permissions.includes('product_and_invoice_viewer');

  const [typesIntersectionIds, setTypesIntersectionIds] = useState<IntersectionIds[]>([]);
  const findTypesApplicableIntersections = useCallback(() => {
    const { types, unique_types } = form.getValues();

    setTypesIntersectionIds(
      types.map((type) =>
        unique_types ? findProductTypesDuplicates(types) : findProductTypesDuplicates([type]),
      ),
    );
  }, [form]);

  const validateIntersections = useCallback(() => {
    //TODO Based on TR-5885 user can only create types for next year
    //Once this is changed validation for types in different years should be discussed and updated
    if (
      typesIntersectionIds.some((int) => {
        const { fullDayIds, halfDayIds } = int;

        return !!fullDayIds.length || halfDayIds.length;
      })
    ) {
      return $t({
        id: isUniqueTypes
          ? 'products-ApplicableStudentsMustBeUniqueWithinAllProductTypes'
          : 'products-ApplicableStudentsMustBeUniqueWithinOneProductType',
      });
    }
  }, [$t, isUniqueTypes, typesIntersectionIds]);

  const handleClose = useCallback(async () => {
    if (
      form.formState.isDirty &&
      !(await getConfirmation({
        textId: 'school-edit-CloseUnsavedConfirmation',
      }))
    )
      return;

    onClose();
  }, [onClose, form.formState.isDirty, getConfirmation]);

  const resetProductSubscriptionFields = useCallback(() => {
    !isUniqueTypes && form.setValue('unique_types', defaultValues.unique_types);
    oneTypeAssignment && form.setValue('assignment.one_type', defaultValues.assignment.one_type);
  }, [form, isUniqueTypes, oneTypeAssignment]);

  const handleSingleTypeChange = useCallback(
    (field: ControllerRenderProps<ProductForm, 'single_type'>) => {
      if (!field.value) {
        //resets subscription fields for single type product
        resetProductSubscriptionFields();

        //removes extra types for single type product on create
        if (types.length > 1) {
          const firstType = types[0];
          form.setValue('types', [firstType]);
        }
      }
      field.onChange(!field.value);
    },
    [form, resetProductSubscriptionFields, types],
  );

  const handleSubmit = useCallback(
    async (v: ProductForm) => {
      switch (state) {
        case ViewState.General: {
          const nameChanged = !product?.id || product.name !== v.name;

          if (!nameChanged) {
            form.clearErrors();
            setState(ViewState.Variants);
            return;
          }

          checkProductNameUnique.mutate(
            {
              schoolId,
              name: v.name,
            },
            {
              onSuccess: ({ is_unique }) => {
                if (!is_unique) {
                  form.setError('name', {
                    type: 'validate',
                    message: $t({ id: 'products-ProductNameExists' }, { name: v.name }),
                  });
                  form.setFocus('name');
                  return;
                }

                form.clearErrors();
                setState(ViewState.Variants);
              },
              onError: showError,
            },
          );

          return;
        }

        case ViewState.Variants: {
          const errorMessage = validateIntersections();
          if (errorMessage) {
            showError({
              message: errorMessage,
            });
            return;
          }
          onSave(v);
          return;
        }

        default:
          return;
      }
    },
    [
      $t,
      checkProductNameUnique,
      form,
      onSave,
      product?.id,
      product?.name,
      schoolId,
      showError,
      state,
      validateIntersections,
    ],
  );

  const handleError = useCallback(() => {
    const errorMessage = validateIntersections();

    if (errorMessage) {
      showError({
        message: errorMessage,
      });
    }
  }, [showError, validateIntersections]);

  if (!frequencies)
    return (
      <ModalSmall open onClose={handleClose}>
        <Loading />
      </ModalSmall>
    );

  const actionsDisabled = isDeleting || checkProductNameUnique.isLoading || isSaving;

  const deleteButton = !!onDelete && (
    <Button
      variant="outlined"
      startIcon={isDeleting ? <Spin /> : <DeleteIcon />}
      disabled={actionsDisabled}
      onClick={onDelete}
    >
      {$t({ id: 'action-Delete' })}
    </Button>
  );

  if (state === ViewState.General)
    return (
      <ModalSmall open onClose={handleClose}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalHeader
              active
              title={
                <Controller
                  control={form.control}
                  name="name"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => {
                    return (
                      <ModalHeaderInput
                        placeholder={$t({ id: 'products-ProductName' })}
                        autoFocus={!field.value}
                        error={!!fieldState.error}
                        helperText={getControllerErrorText(fieldState.error, undefined, $t)}
                        {...field}
                      />
                    );
                  }}
                />
              }
            >
              <IconButton onClick={handleClose}>
                <CrossIcon />
              </IconButton>
            </ModalHeader>
            <ModalContent active>
              <Stack gap={4}>
                <>
                  <Stack flexDirection="row" gap={3} justifyContent="space-between">
                    <Typography variant="h2">
                      {$t({ id: 'products-IsThisProductRequired?' })}
                    </Typography>

                    <Controller
                      control={form.control}
                      name="obligatory"
                      render={({ field }) => {
                        return (
                          <ProductCreateToggle
                            checked={field.value}
                            onChange={() => field.onChange(!field.value)}
                            labelTextIds={[
                              'products-Product-Optional',
                              'products-Product-Required',
                            ]}
                          />
                        );
                      }}
                    />
                  </Stack>

                  <Stack gap={1}>
                    <Typography variant="h2">{$t({ id: 'products-WhatWillTrigger' })}</Typography>
                    <Stack flexDirection="row" gap={1}>
                      <Stack flex={1}>
                        <FormTextField
                          customIcon={
                            <Tooltip title={$t({ id: 'products-AtTheMoment' })}>
                              <IconButton>
                                <InformationIcon />
                              </IconButton>
                            </Tooltip>
                          }
                          disabled
                          label={$t({ id: 'products-Trigger' })}
                          value={$t({ id: 'products-Trigger-Registration' })}
                        />
                      </Stack>
                      <Stack flex={1}>
                        <Controller
                          name="trigger.extra_data.status"
                          control={form.control}
                          rules={{ required: true }}
                          render={({ field, fieldState }) => (
                            <PropertyTypeSelect
                              schoolId={schoolId}
                              userRole={SchoolUserRole.Student}
                              propertyType={SchoolPropertyType.Status}
                              value={field.value}
                              hasError={!!fieldState.error}
                              errorMessage={getControllerErrorText(fieldState.error, undefined, $t)}
                              onChange={field.onChange}
                              label={$t({ id: 'schoolProperty-Status' })}
                              renderEmptyStub={() => <SelectContentSkeleton />}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack flexDirection="row" gap={3} justifyContent="space-between">
                    <Stack flexDirection="row" alignItems="center" gap={1}>
                      <Typography variant="h2">
                        {$t({ id: 'products-WillHaveDifferentTypes' })}
                      </Typography>
                      <Tooltip title={$t({ id: 'products-SeveralTypesLinkedToProduct' })}>
                        <IconButton inverse>
                          <InformationIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>

                    <Controller
                      control={form.control}
                      name="single_type"
                      render={({ field }) => {
                        return (
                          <ProductCreateToggle
                            checked={!field.value}
                            onChange={() => handleSingleTypeChange(field)}
                            labelTextIds={['no', 'yes']}
                            disabled={!!product?.id}
                          />
                        );
                      }}
                    />
                  </Stack>

                  {!isSingleType && <ProductSubscriptionSection canEdit />}
                </>
              </Stack>
            </ModalContent>
            <ModalFooter active sx={{ justifyContent: onDelete ? 'space-between' : undefined }}>
              {deleteButton}
              <Button
                endIcon={checkProductNameUnique.isLoading ? <Spin /> : <ArrowRightIcon />}
                disabled={actionsDisabled}
                type="submit"
              >
                {$t({ id: 'action-Next' })}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalSmall>
    );

  return (
    <ModalLarge open onClose={handleClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit, handleError)}>
          <SchoolProductModalHeader
            name={name}
            obligatory={obligatory}
            statusName={propertiesMap.status.find((s) => s.id === trigger.extra_data.status)?.name}
            onClose={handleClose}
          />

          <ModalContent display="flex" flexDirection="column">
            <SchoolProductCreateModalVariants
              findIntersections={findTypesApplicableIntersections}
              typesIntersectionIds={typesIntersectionIds}
              frequencies={sortedFrequencies}
              form={form}
              schoolId={schoolId}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              yearsForSelect={yearsForSelect}
              defaultYear={defaultYear}
              canViewFrequency={canViewFrequency}
            />
          </ModalContent>
          <ModalFooter active sx={{ justifyContent: onDelete ? 'space-between' : undefined }}>
            {product ? (
              deleteButton
            ) : (
              <Button
                variant="outlined"
                startIcon={<ArrowLeftIcon />}
                disabled={isSaving}
                onClick={() => setState(ViewState.General)}
              >
                {$t({ id: 'action-Back' })}
              </Button>
            )}
            <Button
              startIcon={isSaving ? <Spin /> : <CheckIcon />}
              disabled={isSaving}
              type="submit"
            >
              {$t({ id: 'action-Save' })}
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalLarge>
  );
};

export type IntersectsAll = 'All';
export const INTERSECTS_ALL: IntersectsAll = 'All';

const findProductTypesDuplicates = (v: ProductFormType[]): IntersectionIds => {
  const fullDayVariants = v.map((v) => v.variants.filter((v) => !v.half_day)).flat();
  const halfDayVariants = v.map((v) => v.variants.filter((v) => v.half_day)).flat();

  const getNonUniqueIntersections = (v: ProductSaveVariant[]) => {
    const allCombinations: IntersectionId[] = [];

    for (const variant of v) {
      if (!variant.age_groups.length && !variant.subjects.length) continue;

      const variantAgeGroups = variant.age_groups.length ? variant.age_groups : [INTERSECTS_ALL];

      for (const ageGroupId of variantAgeGroups) {
        const variantSubjects = variant.subjects.length ? variant.subjects : [INTERSECTS_ALL];

        for (const subjectId of variantSubjects) {
          allCombinations.push({
            ageGroupId,
            subjectId,
          });
        }
      }
    }

    return allCombinations.reduce<IntersectionId[]>((acc, comb) => {
      if (
        allCombinations.filter(
          (exComb) => exComb.ageGroupId === comb.ageGroupId && exComb.subjectId === comb.subjectId,
        ).length > 1 ||
        (comb.ageGroupId === INTERSECTS_ALL &&
          allCombinations.filter((exComb) => exComb.subjectId === comb.subjectId).length > 1) ||
        (comb.subjectId === INTERSECTS_ALL &&
          allCombinations.filter(
            (exComb) =>
              exComb.ageGroupId === comb.ageGroupId || exComb.ageGroupId === INTERSECTS_ALL,
          ).length > 1)
      )
        return [...acc, comb];
      return acc;
    }, []);
  };

  return {
    halfDayIds: getNonUniqueIntersections(halfDayVariants),
    fullDayIds: getNonUniqueIntersections(fullDayVariants),
  };
};

type ProductCreateToggleProps = {
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
  labelTextIds: [string, string];
};

export const ProductCreateToggle = ({
  disabled,
  onChange,
  labelTextIds,
  checked,
}: ProductCreateToggleProps) => {
  const { formatMessage } = useIntl();

  return (
    <Stack
      flexDirection="row"
      gap={1}
      sx={{
        '& .MuiTypography-root': {
          color: disabled ? 'common.light2' : 'common.grey',
          '&.selectedOption': {
            color: disabled ? 'common.light2' : 'primary.main',
          },
        },
      }}
    >
      <Typography variant="h3" className={!checked ? 'selectedOption' : undefined}>
        {formatMessage({ id: labelTextIds[0] })}
      </Typography>
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        sx={{
          '& .MuiSwitch-thumb': { bgcolor: 'primary.main' },
          '&.MuiSwitch-root .Mui-disabled': {
            '+.MuiSwitch-track': {
              opacity: 1,
              borderColor: 'common.light2',
            },
            '.MuiSwitch-thumb': {
              bgcolor: 'common.light2',
            },
          },
        }}
      />
      <Typography variant="h3" className={checked ? 'selectedOption' : undefined}>
        {formatMessage({ id: labelTextIds[1] })}
      </Typography>
    </Stack>
  );
};
