import { IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { useGetDependantStudentsForParentQuery, useGetProductsForParentQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import {
  Counter,
  EditIcon,
  EyeIcon,
  ModalSearch,
  TagUser,
  TypographyWithOverflowHint,
} from '@schooly/style';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import AccessDenied from '../../../components/common/AccessDenied';
import EmptyStub from '../../../components/common/ProfileModal/EmptyStub';
import { ProfileModalMode } from '../../../context/profile/helpers';
import { useProfile } from '../../../context/profile/useProfile';
import { getRouteModalPathname } from '../../../helpers/misc';
import useSchoolYears from '../../../hooks/useSchoolYears';
import getIsAccessDeniedError from '../../../utils/getIsAccessDeniedError';
import {
  AssignedProductWithStudentId,
  FeesList,
  FeesListEmpty,
  FeesListSkeleton,
} from './ProfileModalFeesList/ProfileModalFeesList';
import { StudentProductsModalState } from './ProfileModalPayers/StudentProductsModal/StudentProductsModalContent';

export const ProfileModalDependants: FC = () => {
  const { $t } = useIntl();
  const navigate = useNavigate();

  const { actions, schoolMembership } = useProfile();

  const { defaultValidity, isLoading: isYearsFetching } = useSchoolYears();

  const { schoolId = '', permissions } = useAuth();
  const canView = permissions.includes('payer_and_product_assignment_viewer');
  const canEdit = permissions.includes('payer_and_product_assignment_manager');

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStudentId, setSelectedStudentId] = useState('');

  const {
    data: students,
    isLoading: isLoadingStudents,
    error,
  } = useGetDependantStudentsForParentQuery(
    { id: schoolMembership?.relation_id ?? '' },
    {
      enabled: !!schoolMembership?.relation_id,
      refetchOnMount: 'always',
    },
  );

  const { data, isLoading: isProductsLoading } = useGetProductsForParentQuery(
    { relationId: schoolMembership?.relation_id ?? '' },
    {
      enabled: !!schoolMembership?.relation_id,
      refetchOnMount: 'always',
    },
  );

  const products = useMemo(() => {
    const allProducts = data?.map((d) => ({ ...d.product, studentId: d.student_id }));

    if (!selectedStudentId) return allProducts;

    const productsMap =
      data?.reduce(
        (prev: Record<string, AssignedProductWithStudentId[]>, { student_id, product }) => {
          if (!prev[student_id]) {
            prev[student_id] = [{ ...product, studentId: student_id }];
          } else {
            prev[student_id].push({ ...product, studentId: student_id });
          }

          return prev;
        },
        {},
      ) ?? {};

    const products = productsMap[selectedStudentId];

    if (searchQuery) {
      return products?.filter(
        ({ name, variant }) =>
          name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          variant.type_name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }

    return products;
  }, [data, searchQuery, selectedStudentId]);

  const productCount = products?.length ?? 0;

  const studentNamesMap = useMemo(() => {
    const map: Record<string, string> = {};
    if (students) {
      students.forEach(
        (student) => (map[student.id] = student.known_as || student.given_name || ''),
      );
    }
    return map;
  }, [students]);

  const renderStudents = () => {
    return (
      <Stack direction="row" gap={1} flexWrap="wrap" mb={2.5}>
        {students
          ? students?.map((student) => {
              const selected = selectedStudentId === student.id;

              return (
                <TagUser
                  selected={selected}
                  avatar={<AvatarAuth user={student} />}
                  label={studentNamesMap[student.id]}
                  onClick={() =>
                    selected ? setSelectedStudentId('') : setSelectedStudentId(student.id)
                  }
                />
              );
            })
          : [...new Array(3)].map(() => (
              <Skeleton
                variant="rectangular"
                width={120}
                height={44}
                sx={{ '&&': { borderRadius: 100 } }}
              />
            ))}
      </Stack>
    );
  };

  const renderUserName = useCallback(
    (id: string) => {
      const studentName = id ? studentNamesMap[id] : '';

      return (
        <TypographyWithOverflowHint
          component={Link}
          color="text.primary"
          to={{
            pathname: getRouteModalPathname('student', { relation_id: id }),
            hash: ProfileModalMode.PayersAndProducts,
          }}
          sx={{
            '&:hover': { textDecoration: 'underline' },
            '.GridRowItem:hover &': { color: 'primary.main' },
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {studentName}
        </TypographyWithOverflowHint>
      );
    },
    [studentNamesMap],
  );

  const onRowClick = useCallback(
    (product: AssignedProductWithStudentId) => {
      navigate(`/students/${product.studentId}/payers?${StudentProductsModalState.AddProducts}`);
    },
    [navigate],
  );

  const renderFeesList = () => {
    if (isProductsLoading || isYearsFetching || !defaultValidity?.id) return <FeesListSkeleton />;
    if (!products?.length)
      return (
        <FeesListEmpty
          messageId={
            searchQuery ? 'profile-NoResultsMatching' : 'profile-ThereAreNoActiveProductAssignments'
          }
        />
      );

    return (
      <FeesList
        products={products}
        schoolId={schoolId}
        renderUserName={renderUserName}
        onRowClick={canEdit ? onRowClick : undefined}
        yearId={defaultValidity.id}
        endIcon={
          <IconButton
            sx={{ visibility: 'hidden', '.GridRowItem:hover &': { visibility: 'visible' } }}
          >
            <EditIcon />
          </IconButton>
        }
      />
    );
  };

  if (getIsAccessDeniedError(error) || !canView) {
    return <AccessDenied />;
  }

  const isEmpty = !isLoadingStudents && !students?.length;

  if (isEmpty) {
    return (
      <EmptyStub
        tabNameTextId="profile-Products"
        mode={ProfileModalMode.DependantsAndProducts}
        messageTextId="profile-NoStudentAssigned"
        buttonTextId="profile-GoToFamilyPage"
        onButtonClick={() => actions.setMode(ProfileModalMode.Family)}
        buttonIcon={<EyeIcon />}
      />
    );
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={1.75}
        mt={-0.75}
      >
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="profile-Products" />
          </Typography>
          {!!productCount && <Counter>{productCount}</Counter>}
        </Stack>
        <ModalSearch
          value={searchQuery}
          onChange_MemoizedCallbackOnly={setSearchQuery}
          placeholder={$t({ id: 'people-Search' })}
        />
      </Stack>

      {renderStudents()}
      {renderFeesList()}
    </>
  );
};
