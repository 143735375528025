import { Stack, Typography } from '@mui/material';
import { getTypedObjectKeys, PayableFee } from '@schooly/api';
import { Grid } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format, getMonth, getYear } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { EmptySvg } from '../../../pages/ProfileModal/tabs/ProfileModalFeesList/EmptyListSvg';
import { PayableFeeRow } from './PayableFeeRow';
import { PayableFeesRelationType } from './PayableFees';

type PayableFeesContentProps = {
  data: PayableFee[];
  schoolId: string;
  relationType: PayableFeesRelationType;
  expanded?: boolean;
};

export const PayableFeesContent: FC<PayableFeesContentProps> = ({
  data,
  schoolId,
  relationType,
  expanded,
}) => {
  const payableFees = useMemo(
    () =>
      data
        .sort((a, b) => {
          const dateA = newDateTimezoneOffset(b.issue_date);
          const dateB = newDateTimezoneOffset(a.issue_date);
          const byYear = getYear(dateA) - getYear(dateB);
          if (byYear !== 0) return byYear;

          return getMonth(dateA) - getMonth(dateB);
        })
        .reduce<Record<string, PayableFee[]>>((acc, next) => {
          const date = newDateTimezoneOffset(next.issue_date);
          const label = `${format(date, 'MMM')} ${getYear(date)}`;
          const data = acc[label];

          return { ...acc, [label]: data ? [...data, next] : [next] };
        }, {}),
    [data],
  );

  const labels = getTypedObjectKeys(payableFees);
  const { $t } = useIntl();

  if (!labels.length) {
    return (
      <Stack flex={1} alignItems="center" gap={2.5} p={2} pt={5}>
        <EmptySvg />
        <Typography textAlign="center" variant="h3" color="text.primary" maxWidth={280}>
          {$t({ id: 'profile-NoResultsMatching' })}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack gap={2.5}>
      {labels.map((label) => (
        <Stack key={label} gap={1}>
          <Typography variant="h4">{label}</Typography>
          <Grid
            sx={(theme) => ({
              overflow: 'hidden',
              borderRadius: theme.spacing(1),
              '& .MuiTableRow-root': {
                height: 44,
              },
            })}
          >
            {payableFees[label].map((payableFee, i) => {
              const [student] = payableFee.students;
              const key = `${payableFee.status}-${payableFee.due_date}-${payableFee.issue_date}-${
                student ? student.id : ''
              }`;
              const isLastFeeRow = i === payableFees[label].length - 1;

              return (
                <PayableFeeRow
                  key={key}
                  isLastFeeRow={isLastFeeRow}
                  payableFee={payableFee}
                  expanded={expanded}
                  relationType={relationType}
                  schoolId={schoolId}
                />
              );
            })}
          </Grid>
        </Stack>
      ))}
    </Stack>
  );
};
