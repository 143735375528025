import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { ProductSubscriptionRadioGroup } from './ProductSubscriptionRadioGroup';

export const ProductSubscriptionSection: FC<{ canEdit: boolean }> = ({ canEdit }) => {
  const { formatMessage } = useIntl();

  return (
    <Stack gap={1} justifyContent="space-between">
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Typography variant="h2">
          {formatMessage({ id: 'products-CanStudentSubscribeToMultiple' })}
        </Typography>
      </Stack>
      <ProductSubscriptionRadioGroup disabled={!canEdit} />
    </Stack>
  );
};
