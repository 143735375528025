import { IconButton, Skeleton, Stack, TableRow, Typography } from '@mui/material';
import { PayableFee, PayerType } from '@schooly/api';
import { DATE_FORMAT } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ChevronUpIcon,
  GridBody,
  GridCell,
  NewTabIcon,
  Price,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { format } from 'date-fns';
import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { getCurrencySymbol } from '../../../pages/School/SchoolProducts/helpers';
import { getStudentName, payableFeeStatusColor } from './helpers';
import { PayableFeeItemRow } from './PayableFeeItemRow';
import { PayableFeesRelationType } from './PayableFees';

export type PayableFeeRowProps = {
  payableFee: PayableFee;
  schoolId: string;
  relationType: PayableFeesRelationType;
  isLastFeeRow: boolean;
  expanded?: boolean;
};

export const PayableFeeRow: FC<PayableFeeRowProps> = ({
  payableFee,
  relationType,
  isLastFeeRow,
  expanded,
}) => {
  const [isRowExpanded, expandRow, collapseRow] = useFlag(false);
  const { $t } = useIntl();
  const { students, payer, issue_date, due_date, total_payment, status, total_paid, currency } =
    payableFee;
  const [student] = students;

  const currencySymbol = getCurrencySymbol(currency);

  const payerName =
    payer.type === PayerType.Company ? payer.data.name : getUserFullName(payer.data);

  const studentDependant = relationType === 'student' ? payerName : null;
  const companyDependant = relationType === 'company' && student ? getUserFullName(student) : null;
  const parentDependant = relationType === 'parent' && student ? getStudentName(student) : null;

  const getStatusInfo = () => {
    const status = $t({ id: `payableFees-status-${payableFee.status}` });
    if (payableFee.status === 'partially_paid' && total_paid) {
      return `${status} ${currencySymbol} ${total_paid?.toLocaleString()} / ${total_payment.toLocaleString()}`;
    }

    return status;
  };

  useEffect(() => {
    if (typeof expanded === 'undefined') return;

    if (expanded) expandRow();
    else collapseRow();
  }, [collapseRow, expandRow, expanded]);

  const statusColor = payableFeeStatusColor[status];
  const borderBottom = !isLastFeeRow || isRowExpanded;

  return (
    <GridBody>
      <TableRow
        sx={(theme) => ({
          cursor: 'pointer',
          '&:hover': {
            'td.MuiTableCell-root': {
              backgroundColor: theme.palette.background.default,
            },
          },
        })}
        onClick={isRowExpanded ? collapseRow : expandRow}
      >
        <GridCell borderBottom={borderBottom} noVerticalPadding>
          <Stack direction="row" gap={1} width={208}>
            <Typography color="common.grey">
              {format(newDateTimezoneOffset(issue_date), 'dd MMM').toUpperCase()}
            </Typography>
            <Typography color="common.grey">{$t({ id: 'payableFees-DueOn' })}</Typography>
            <Typography color="common.grey2">
              {format(newDateTimezoneOffset(due_date), DATE_FORMAT)}
            </Typography>
          </Stack>
        </GridCell>

        <GridCell borderBottom={borderBottom} noVerticalPadding>
          <TypographyWithOverflowHint width={184} color={statusColor}>
            <Typography
              color="text.primary"
              display="inline"
              {...(parentDependant && {
                component: Link,
                to: { pathname: `/students/${student.id}` },
                sx: {
                  '&:hover': { textDecoration: parentDependant ? 'underline' : undefined },
                },
              })}
            >
              {studentDependant || companyDependant || parentDependant}
            </Typography>
          </TypographyWithOverflowHint>
        </GridCell>

        <GridCell borderBottom={borderBottom} noVerticalPadding width={110}>
          <Typography color="text.primary">
            <Price currency={currencySymbol} variant="body1" price={total_payment} />
          </Typography>
        </GridCell>

        <GridCell borderBottom={borderBottom} noVerticalPadding>
          <TypographyWithOverflowHint width={165} color={statusColor}>
            <Typography display="inline" color={statusColor}>
              {getStatusInfo()}
            </Typography>
          </TypographyWithOverflowHint>
        </GridCell>

        <GridCell borderBottom={borderBottom} noVerticalPadding width={40}>
          {!!payableFee.invoice_link && (
            <Link to={payableFee.invoice_link} target="_blank" onClick={(e) => e.stopPropagation()}>
              <IconButton size="small" inverse>
                <NewTabIcon />
              </IconButton>
            </Link>
          )}
        </GridCell>

        <GridCell
          borderBottom={borderBottom}
          width={35}
          onClick={(e) => {
            e.preventDefault();
            if (isRowExpanded) collapseRow();
            else expandRow();
          }}
          noVerticalPadding
        >
          <IconButton
            sx={(theme) => ({
              width: theme.spacing(2),
              color: isRowExpanded ? theme.palette.primary.main : undefined,
              transform: !isRowExpanded ? 'rotate(180deg)' : undefined,
            })}
            inverse
          >
            <ChevronUpIcon />
          </IconButton>
        </GridCell>
      </TableRow>
      {isRowExpanded &&
        payableFee.items.map((item, i) => {
          const isLastItemRow = i === payableFee.items.length - 1;
          const key = `${item.product_id}-${item.variant_id}`;
          return (
            <PayableFeeItemRow
              key={key}
              item={item}
              borderBottom={isLastFeeRow ? !isLastItemRow : true}
              currencySymbol={currencySymbol}
            />
          );
        })}
    </GridBody>
  );
};

export type PayableFeesSkeletonRowsProps = {
  rowsCount: number;
  cellsCount: number;
};

export const PayableFeesSkeletonRows: FC<PayableFeesSkeletonRowsProps> = ({
  rowsCount,
  cellsCount,
}) => {
  const skeleton = (
    <Typography>
      <Skeleton variant="text" />
    </Typography>
  );
  return (
    <>
      {[...new Array(rowsCount)].map((r) => (
        <TableRow key={r}>
          {[...new Array(cellsCount)].map((c) => (
            <GridCell noVerticalPadding key={c}>
              {skeleton}
            </GridCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
