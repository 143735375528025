import { Stack } from '@mui/material';
import { ProductForm } from '@schooly/api';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';

import { ProductSubscriptionOption, ProductSubscriptionType } from './ProductSubscriptionOption';

export const ProductSubscriptionRadioGroup: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { control, setValue, watch } = useFormContext<ProductForm>();
  const isUniqueTypes = watch('unique_types');

  return (
    <Stack direction="row" alignItems="stretch" gap={1}>
      <Controller
        control={control}
        name="unique_types"
        render={({ field }) => {
          return (
            <ProductSubscriptionOption
              disabled={disabled}
              field={field}
              checked={field.value}
              optionType={ProductSubscriptionType.UniqueTypes}
              labelTextId="products-ProductAssignmentType-UniqueTypes"
              onChange={() => {
                if (!field.value) {
                  field.onChange(!field.value);
                  setValue('assignment.one_type', false);
                }
              }}
            />
          );
        }}
      />

      {/* TR-6609 One type select for product assignment will be available in future */}
      {/* <Controller
        control={control}
        name="assignment.one_type"
        render={({ field }) => {
          return (
            <ProductSubscriptionOption
              disabled={disabled}
              field={field}
              checked={field.value}
              optionType={ProductSubscriptionType.OneTypeAssignment}
              labelTextId="products-ProductAssignmentType-OneTypeAssignment"
              onChange={() => {
                if (!field.value) {
                  field.onChange(!field.value);
                  if (isUniqueTypes) setValue('unique_types', false);
                }
              }}
            />
          );
        }}
      /> */}

      <Controller
        control={control}
        name="assignment.one_type"
        render={({ field }) => {
          return (
            <ProductSubscriptionOption
              disabled={disabled}
              field={field}
              checked={!isUniqueTypes && !field.value}
              optionType={ProductSubscriptionType.ManyTypesAssignment}
              labelTextId="products-ProductAssignmentType-ManyTypesAssignment"
              onChange={() => {
                if (field.value) field.onChange(!field.value);
                if (isUniqueTypes) setValue('unique_types', false);
              }}
            />
          );
        }}
      />
    </Stack>
  );
};
